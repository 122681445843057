// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../Assets/logo_truxt.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloaderBg {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid red; */
}

.preloader {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
  background-size: 150px;
  width: 300px;
  height: 300px;
}

.preloader2 {
  border: 5px solid #f2f2f2;
  border-top: 5px solid #003f75;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  animation: spin 1s ease-in-out infinite;
  position: absolute; /* Position this relative to its container */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.response-card-loader{
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Loader/Loader.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,MAAM;EACN,OAAO;EACP,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,oEAA+D;EAC/D,sBAAsB;EACtB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,6BAA6B;EAC7B,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,uCAAuC;EACvC,kBAAkB,EAAE,4CAA4C;AAClE;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,gBAAgB;AAClB","sourcesContent":[".preloaderBg {\n  position: fixed;\n  z-index: 10;\n  top: 0;\n  left: 0;\n  background: #fff;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  /* border: 2px solid red; */\n}\n\n.preloader {\n  background: url(\"../../Assets/logo_truxt.png\") no-repeat center;\n  background-size: 150px;\n  width: 300px;\n  height: 300px;\n}\n\n.preloader2 {\n  border: 5px solid #f2f2f2;\n  border-top: 5px solid #003f75;\n  border-radius: 50%;\n  width: 250px;\n  height: 250px;\n  animation: spin 1s ease-in-out infinite;\n  position: absolute; /* Position this relative to its container */\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n.response-card-loader{\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
