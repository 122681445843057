// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sandbox {
  height: 100vh;
  overflow: hidden;
  display: flex;
  transition: width 0.5s ease-in-out;
}

.page_wrapper {
  display: flex;
}

.page_wrapper_w_95 {
  width: 95%;
  transition: width 0.5s ease-in-out;
}

.page_wrapper_w_80 {
  width: calc(100vw - 300px);
  transition: width 0.5s ease-in-out;
}

@media screen and (max-width: 1040px) {
  .sandbox{
    min-height:100vh;
  }
}

@media screen and (max-width:768px)
{
  .page_wrapper_w_95{
    width: 100%;
    margin-left: 10%;
  }
  .page_wrapper_w_80{
    width: 100%;
    transition: width 0.5s ease-in-out;
  }
  .sandbox{
    min-height: 100vh;
    overflow: visible;
  }
}


@media screen and (max-width: 480px) {
  .page_wrapper_w_95 {
    width: 100%;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    height: max-content;
    padding-bottom: 30px;
    height: 100%;
    margin-left: 0;
  }
  .page_wrapper_w_80 {
    width: 0;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  .sandbox {
    min-height: 100vh;
    overflow: visible;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,aAAa;EACb,kCAAkC;AACpC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,kCAAkC;AACpC;;AAEA;EACE,0BAA0B;EAC1B,kCAAkC;AACpC;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;;EAEE;IACE,WAAW;IACX,gBAAgB;EAClB;EACA;IACE,WAAW;IACX,kCAAkC;EACpC;EACA;IACE,iBAAiB;IACjB,iBAAiB;EACnB;AACF;;;AAGA;EACE;IACE,WAAW;IACX,UAAU;IACV,oCAAoC;IACpC,mBAAmB;IACnB,oBAAoB;IACpB,YAAY;IACZ,cAAc;EAChB;EACA;IACE,QAAQ;IACR,UAAU;IACV,oCAAoC;EACtC;EACA;IACE,iBAAiB;IACjB,iBAAiB;EACnB;AACF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.sandbox {\n  height: 100vh;\n  overflow: hidden;\n  display: flex;\n  transition: width 0.5s ease-in-out;\n}\n\n.page_wrapper {\n  display: flex;\n}\n\n.page_wrapper_w_95 {\n  width: 95%;\n  transition: width 0.5s ease-in-out;\n}\n\n.page_wrapper_w_80 {\n  width: calc(100vw - 300px);\n  transition: width 0.5s ease-in-out;\n}\n\n@media screen and (max-width: 1040px) {\n  .sandbox{\n    min-height:100vh;\n  }\n}\n\n@media screen and (max-width:768px)\n{\n  .page_wrapper_w_95{\n    width: 100%;\n    margin-left: 10%;\n  }\n  .page_wrapper_w_80{\n    width: 100%;\n    transition: width 0.5s ease-in-out;\n  }\n  .sandbox{\n    min-height: 100vh;\n    overflow: visible;\n  }\n}\n\n\n@media screen and (max-width: 480px) {\n  .page_wrapper_w_95 {\n    width: 100%;\n    opacity: 1;\n    transition: opacity 0.5s ease-in-out;\n    height: max-content;\n    padding-bottom: 30px;\n    height: 100%;\n    margin-left: 0;\n  }\n  .page_wrapper_w_80 {\n    width: 0;\n    opacity: 0;\n    transition: opacity 0.5s ease-in-out;\n  }\n  .sandbox {\n    min-height: 100vh;\n    overflow: visible;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
