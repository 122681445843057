// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.loading-button-message{
    color: white;
}
.loading-button-progress svg circle{
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/Components/Buttons/LoadingButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;AACZ;AACA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB","sourcesContent":[".loading-button{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 5px;\n}\n.loading-button-message{\n    color: white;\n}\n.loading-button-progress svg circle{\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
